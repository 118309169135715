// export const API_ROOT = 'http://34.124.237.82:8091';
// export const API_ROOT = 'http://io.bridgetechcard.com';development
// export const API_ROOT =
//   process.env.NODE_ENV === "production"
//     ? "https://bridgetechcard.com"
//     : "http://1.94.102.184:8091";
export const API_ROOT = "http://1.94.102.184:8091"; // 测试环境
// export const API_ROOT = "https://bridgetechcard.com"; // 生产环境
// export const API_ROOT =
//   process.env.NODE_ENV === "production"
//     ? "https://io.bridgetechcard.com"
//     : "http://1.94.102.184:8091";
export const JWT_SECRET_KEY = "HX&bYgx3dgyIX5!";
export const JWT_SECRET = true; //是否jwt
