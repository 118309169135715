import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Import Element Plus and its styles
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

// Import the Chinese language pack for Element Plus
import zhCn from "element-plus/es/locale/lang/zh-cn";
// import en from "element-plus/es/locale/lang/en";

import i18n from "@/utils/lang";

import pinia from "./stores"; //引入
const app = createApp(App);

// Use Element Plus with Chinese as the default language
app.use(ElementPlus, {
  locale: zhCn,
});
// if (i18n.global.locale.value == "zh") {
//   app.use(ElementPlus, {
//     locale: zhCn,
//   });
// } else {
//   app.use(ElementPlus, {
//     locale: en,
//   });
// }

app.use(router);

// 全局注入语言
app.use(i18n);
app.use(pinia);

app.mount("#app");

// 解决elementplus改变屏幕大小时el-table报错ResizeObserver loop limit exceeded
const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
};
