<template>
  <div class="login-container">
    <div class="banner">
      <img src="@/assets/pages/login/login_banner.png" alt="Login Banner" />
    </div>
    <div class="login-form">
      <div class="form-wrapper">
        <h2>{{ $t("login.login") }}</h2>
        <form>
          <div class="form-group">
            <label for="username">{{ $t("login.username") }}：</label>
            <input
              type="text"
              id="username"
              v-model="username"
              :placeholder="$t('login.username_placeholder')"
            />
          </div>
          <div class="form-group">
            <label for="password">{{ $t("login.password") }}：</label>
            <input
              type="password"
              id="password"
              v-model="password"
              :placeholder="$t('login.password_placeholder')"
            />
          </div>
          <!-- 验证码 -->
          <div class="form-group">
            <label for="password">{{ $t("login.captcha") }}：</label>
            <input
              type="text"
              id="code"
              v-model="code"
              :placeholder="$t('login.captcha_placeholder')"
              style="margin-right: 5px"
            />
            <el-button
              @click="sendCode"
              style="height: 42px"
              :loading="codeLoading"
              :disabled="codeDisabled"
            >
              {{ codeText }}</el-button
            >
          </div>
          <div class="form-options">
            <div class="remember-me">
              <!-- <input type="checkbox" id="remember" /> -->
              <el-checkbox v-model="rememberMe" size="small" text-color="#000">
                <label class="remember-password" for="remember" style="">{{
                  $t("login.remember_password")
                }}</label>
              </el-checkbox>
            </div>
            <a href="#" class="forgot-password" @click.prevent="goTo('/forget')"
              >{{ $t("login.forgotten_password") }}?</a
            >
          </div>
          <!-- 有问题，不发请求 -->
          <!-- <button  @click="handleLogin" class="login-button">
            {{ $t("login.log_in") }}
          </button> -->
          <el-button type="primary" @click="handleLogin" class="login-button">{{
            $t("login.log_in")
          }}</el-button>

          <div class="terms">
            <!-- <input type="checkbox" id="agree" /> -->
            <el-checkbox v-model="agreeTerms" size="small" text-color="#000">
              <label for="agree" style="font-size: 12px">
                {{ $t("login.pact_label") }}
                <a href="#">《{{ $t("login.service_agreement") }}》</a>
                {{ $t("login.and") }}
                <a href="#">《{{ $t("login.privacy_policy") }}》</a>
              </label>
            </el-checkbox>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import apiClient from "@/services/api";
import router from "@/router";
import { ElMessage } from "element-plus";
import { onMounted, ref } from "vue";
import { getCurrentInstance } from "vue";

const username = ref("");
const password = ref("");
const code = ref("");
const rememberMe = ref(false);
const agreeTerms = ref(true);

const {
  appContext: {
    config: { globalProperties },
  },
} = getCurrentInstance(); // 这里可以根据需要写个hook

// 跳转
const goTo = (path) => {
  router.push({ path: path });
};
// 登录
const handleLogin = async () => {
  if (!agreeTerms.value) {
    ElMessage({
      message: globalProperties.$t("login.consent_agreement_reminder"),
      type: "warning",
    });
    return;
  }

  if (!code.value) {
    ElMessage({
      message: globalProperties.$t("login.captcha_placeholder"),
      type: "warning",
    });
    return;
  }
  try {
    const captRes = await apiClient.post("/acc/check_code", {
      user: username.value,
      action: "login",
      code: code.value,
    });
    if (captRes.code == 0 && captRes.data.auth) {
      // 验证码通过
      const response = await apiClient.post("/acc/login", {
        auth: captRes.data.auth,
        pwd: password.value,
      });

      if (response.code == 0 && response.data) {
        // console.log(response);

        localStorage.setItem("jwt_token", response.data.access_token);
        localStorage.setItem(
          "jwt_userInfo",
          JSON.stringify({
            user: username.value,
          })
        );

        if (rememberMe.value) {
          localStorage.setItem("remember_me", true);
        } else {
          localStorage.removeItem("remember_me");
        }

        // router.push({ name: "Home" }); // Adjust the route name to your actual home route
        router.push({ name: "Product" }); // 没首页 跳转 product
      } else {
        ElMessage({
          message: response.message,
          type: "warning",
        });
      }
    } else {
      ElMessage({
        message: globalProperties.$t("login.captcha_input_error"),
        type: "warning",
      });
    }
  } catch (error) {
    console.error("Login failed", error);
    ElMessage({
      message: globalProperties.$t("login.login_fail_reminder"),
      type: "warning",
    });
  }
};

// 验证码按钮
const codeTimer = ref();
const codeDisabled = ref(false);
const codeLoading = ref(false);
const codeText = ref("Captcha");
const countdown = () => {
  let second = 60; // 默认时间
  codeLoading.value = false; // 取消加载
  codeDisabled.value = true; // 禁用按钮
  codeText.value = `${second}s`; // 按钮文本
  // 判断是否存在定时器，存在则先清除
  if (codeTimer.value) {
    clearInterval(codeTimer.value);
  }
  // 开启定时器
  codeTimer.value = setInterval(() => {
    second -= 1;
    codeText.value = `${second}s`; // 按钮文本
    if (second <= 0) {
      if (globalProperties.$i18n.locale === "zh") codeText.value = "获取验证码";
      else codeText.value = `Captcha`; // 按钮文本
      codeDisabled.value = false; // 启用按钮
      clearInterval(codeTimer.value); // 清除倒计时
    }
  }, 1000);
};
// 发送验证码
const sendCode = async () => {
  if (!username.value) {
    ElMessage({
      message: globalProperties.$t("login.enter_account_number_prompt"),
      type: "warning",
    });
    return;
  }
  try {
    const response = await apiClient.post("/acc/get_code", {
      user: username.value,
      action: "login",
    });
    // console.log("response", response);

    countdown();
    if (response.code == 0) {
      ElMessage({
        message: globalProperties.$t("login.captcha_sending"),
        type: "success",
      });
    } else {
      ElMessage({
        message: globalProperties.$t("login.captcha_sending_fail"),
        type: "warning",
      });
    }
  } catch (error) {
    console.error("Error sending code", error);
    ElMessage({
      message: globalProperties.$t("login.captcha_sending_fail"),
      type: "warning",
    });
    codeLoading.value = false;
    if (globalProperties.$i18n.locale === "zh") codeText.value = "获取验证码";
    else codeText.value = `Captcha`; // 按钮文本
  }
};
onMounted(() => {
  const token = localStorage.getItem("jwt_token");
  if (token) {
    router.push({ name: "Home" });
  }
  if (globalProperties.$i18n.locale === "zh") codeText.value = "获取验证码";
});
</script>

<style scoped>
/* 修改复选框选中颜色 */
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000;
}

h2 {
  font-family: Avenir;
}

.login-container {
  display: flex;
  height: 100vh;
}

.banner {
  width: 50%;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 60px;
  background-color: #f7f8fa;
  margin-top: -100px;
}

.form-wrapper {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

h2 {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

label {
  font-size: 14px;
  margin-bottom: 5px;
  min-width: 60px;
  font-family: Avenir;
}

input[type="text"],
input[type="password"] {
  flex: 1;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 60px;
}

#remember {
  cursor: pointer;
  margin-left: 0px;
  margin-right: 5px;
}

.remember-me {
  display: flex;
  align-items: center;
  color: #9f9f9f;
  cursor: pointer;
}

.remember-password {
  cursor: pointer;
  /* top: 2px;
  position: relative; */
}

.forgot-password {
  color: #9f9f9f;
  text-decoration: none;
  font-size: 14px;
  font-family: Avenir;
}

.login-button {
  width: 100%;
  padding: 25px 15px;
  font-size: 18px;
  color: #fff;
  background-color: #035dff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.login-button:hover {
  background-color: #0056b3;
}

.terms {
  margin-top: 30px;
  font-size: 12px;
  font-family: Avenir;
  color: #999;
  text-align: center;
  display: flex;
  align-items: flex-start;
}

.terms input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}

.terms a {
  color: #007bff;
  text-decoration: none;
}
</style>
