module.exports = {
  language: {
    name: "English",
  },
  login: {
    login: "login",
    register: "register",
    logout: "logout",
    username: "username",
    username_placeholder: "Please enter your e-mail account",
    password: "password",
    password_placeholder: "Please enter your password",
    captcha: "captcha",
    captcha_placeholder: "Please enter the verification code",
    dispatch: "dispatch",
    remember_password: "Remember password",
    forgotten_password: "Forgotten password",
    log_in: "Log In",
    pact_label: "By logging in, you are deemed to have read and agreed to",
    service_agreement: "Service Agreement",
    and: "and",
    privacy_policy: "Privacy Policy",
    consent_agreement_reminder:
      "Please agree to the Service Agreement and Privacy Agreement",
    login_fail_reminder: "Login failed, please check your account information",
    enter_account_number_prompt:
      "Please enter your account number to receive the verification code",
    captcha_sending:
      "Verification code has been sent, please check your mailbox",
    captcha_sending_fail: "Failed to send verification code, please try again",
    captcha_input_error: "Verification code input error",
    new_password: "New Password",
    new_password_fill: "Please enter new password",
    submit: "submit",
    go_to_login: "Go to Login",
  },
  public: {
    input_placeholder: "Please enter",
    select_placeholder: "Please select",
    home: "Home Page",
    product_info: "Product Information",
    card_manage: "Card Management",
    financial_statements: "Financial Reconciliation Statements",
    card_details: "Card Details",
    view_introduction: "View Introduction",
    being_used: "Being Used",
    card_list: "Card List",
    wildcards: "Wildcards",
    add: "Additional",
    export: "Export",
    batch: "Batch",
    all: "All",
    in_use: "In Use",
    other_statuses: "Other Statuses",
    last_four: "Last four digits of card number",
    addr_last_four: " Last four digits of recharge address",
    all_segments: "All Segments",
    all_tags: "All Tags",
    cardholder_name: "Cardholder Name",
    all_card_types: "All Card Types",
    select_date_range: "Select Date Range",
    start_date: "Start Date",
    end_date: "End Date",
    card_number: "Card Number",
    cardholder: "Cardholder",
    refresh: "Refresh",
    expiry_date: "Expiry Date",
    security_code: "Security Code",
    card_status: "Card Status",
    frozen: "Frozen",
    unlicensed: "Unissued",
    accumulated_spending: "Accumulated Spending",
    available_balance: "Available Balance",
    operation: "Operation",
    recharge: "Recharge",
    transfer: "Transfer",
    details: "Details",
    apply_for_physical_card: "Apply for Physical Card",
    order_number: "Order Number",
    status: "Status",
    successful: "Successful",
    failed: "Failed",
    time: "Time",
    type: "Type",
    amount: "Amount",
    view_order: "View Order",
    chargeback: "Chargeback",
    confirm: "Confirm",
    cancel: "Cancel",
    product: "product",
    prod_reminder: "Please select product",
    last_name: "last name",
    last_name_reminder: "Please enter your last name",
    first_name: "first name",
    first_name_reminder: "Please enter your first name",
    country_code: "country code",
    country_code_reminder: "Please enter the country code",
    phone: "phone",
    phone_reminder: "Please enter your cell phone number",
    email: "email",
    email_reminder: "Please enter your e-mail address",
    remark: "remark",
    remark_reminder: "Please enter a note",
    successful_card_opening: "Successful card opening",
    system_is_busy: "The system is busy, please try again later",
    breakdown_of_income_and_expenditure: "Breakdown of income and expenditure",
    chargebacks: "chargebacks",
    normal_use: "normal use",
    copy: "copy",
    copied: "copied",
    send_one_link: "Send a one-time link",
    freeze: "freeze",
    unfreeze: "unfreeze",
    freeze_successful: "Freeze successful",
    successful_defrosting: "Successful defrosting",
    limit_transfer: "limit_transfer",
    check_consumption_records: "Consumption Records",
    check_recharge_records: "Recharge records",
    page_not_found: "Page not found",
    back: "Back",
    sent_successfully: "Sent successfully",
    transfer_card_id: "Transfer Card ID",
    target_card_id: "Target Card ID",
    transfer_card_id_reminder: "Please enter the transfer card ID",
    transfer_amount_reminder: "Please enter the transfer amount",
    transfer_target_card_id_reminder: "Please enter the target card id",
    line_transfer_successful: "Line Transfer Successful",
    it_must_be_a_number: "Must be a number",
    time_reminder: "Please select a time zone",
    successful_query: "Successful query",
    currency: "currency",
    fee: "fee",
    no_data: "No Data",
    create_time: "Creation time",
    recharge_address: "Recharge Address",
    chain: "chain",
    addr: "addr",
    selection_least_one_reminder:
      "Please select at least one piece of data first",
    cell_title: {
      merchant: "Trader",
      biz_id: "Recharge ID",
      card_uid: "Card ID",
      uid: "Card Merchant Card ID",
      number_ending: "Cardinal Number",
      biz_time: "Recharge Time",
      recharge_amount: "Recharge Amount",
      recharge_cost: "Cost Attribution",
      card_opening_cost: "Card Opening Costs",
      card_opening_income: "Revenue from card issuance",
      topup_amount: "Charge Card Amount",
      topup_cost: "Rechargeable Card Costs",
      topup_income: "Rechargeable card revenue",
    },
    user_list: "User List",
    merchant: "merchant",
    merchant_manage: "Merchant Management",
    user_added_successfully: "User added successfully",
    submitted_successfully: "Submitted successfully",
    modified_successfully: "Modified successfully",
    deleted_successfully: "Deleted successfully",
    deleted_reminder: "Are you sure you want to delete it?",
    warning: "Warning",
    card_status_new: {
      UNKNOWN: "UNKNOWN",
      INACTIVE: "INACTIVE",
      ACTIVE: "ACTIVE",
      FROZEN: "FROZEN",
      DELETED: "DELETED",
      FROZEN_SYS: "FROZEN_SYS",
      RISKY: "RISKY",
    },
    view: "view",
    balance: "Balance",
    timeframe: "Timeframe",
    time_frame: {
      this_week: "This Week",
      this_month: "This Month",
      last_week: "Last Week",
      last_month: "Last Month",
      last7Days: " Last 7 days",
      last30Days: "Last 30 days",
      allTime: "All dates",
      customized: "Customized",
    },
  },
};
