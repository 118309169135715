<template>
  <div class="sidebar">
    <div class="logo">
      <!-- <img src="@/assets/icons/logo_top_left.png" alt="Logo" /> -->
      <span>BridgeCard</span>
    </div>
    <nav class="menu">
      <!-- <router-link
        to="/"
        :class="['menu-item', isActive('/') ? 'active' : '']"
        exact-active-class="active"
      >
        <img
          :src="isActive('/') ? menuIcons.homeActive : menuIcons.homeInactive"
          :alt="$t('public.home')"
        />
        <span>{{ $t("public.home") }}</span>
      </router-link> -->
      <router-link
        to="/product"
        :class="['menu-item', isActive('/product') ? 'active' : '']"
        exact-active-class="active"
      >
        <img
          :src="
            isActive('/product')
              ? menuIcons.productActive
              : menuIcons.productInactive
          "
          :alt="$t('public.product_info')"
        />
        <span>{{ $t("public.product_info") }}</span>
      </router-link>
      <router-link
        to="/card"
        :class="['menu-item', isActive('/card') ? 'active' : '']"
        exact-active-class="active"
      >
        <img
          :src="
            isActive('/card') ? menuIcons.cardActive : menuIcons.cardInactive
          "
          :alt="$t('public.card_manage')"
        />
        <span>{{ $t("public.card_manage") }}</span>
      </router-link>
      <router-link
        to="/statementOfAccount"
        :class="['menu-item', isActive('/statementOfAccount') ? 'active' : '']"
        exact-active-class="active"
      >
        <img
          :src="
            isActive('/statementOfAccount')
              ? menuIcons.statementActive
              : menuIcons.statementInactive
          "
          :alt="$t('public.financial_statements')"
        />
        <span>{{ $t("public.financial_statements") }}</span>
      </router-link>
    </nav>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {useRouter } from 'vue-router'

const menuIcons = ref({
  homeActive: require("@/assets/icons/menu_home_active.png"),
  homeInactive: require("@/assets/icons/menu_home_inactive.png"),
  productActive: require("@/assets/icons/menu_product_active.png"),
  productInactive: require("@/assets/icons/menu_product_inactive.png"),
  cardActive: require("@/assets/icons/menu_card_active.png"),
  cardInactive: require("@/assets/icons/menu_card_inactive.png"),
  statementActive: require("@/assets/icons/menu_statement_of_account_active.png"),
  statementInactive: require("@/assets/icons/menu_statement_of_account_inactive.png"),
});
const router = useRouter()
const isActive = (route) => {
  return router.currentRoute.value.fullPath === route;
};
</script>

<style scoped>
@media only screen and (max-width: 1028px) {
  .menu-item {
    width: 20px;
  }
}

/* 将 sidebar 和相关样式移到这里 */
.sidebar {
  width: 18%;
  background-color: #fff;
}

.logo {
  width: 100%;
  height: 57px;
  /* padding: 8px 0px 8px 0px; */
  background-color: #035dff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.logo img {
  width: 40px; /* Adjust the size of the logo if necessary */
  height: auto;
  margin-left: -40px;
}

.logo span {
  /* margin-left: 18px; */
  font-size: 20px;
  color: #fff;
}

.menu {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.menu-item {
  align-items: center;
  margin-bottom: 12px;
  padding: 15px;
  text-decoration: none;
  color: #707070;
  border-radius: 10px;
  transition: background-color 0.3s;
  font-size: 16px;
  white-space: nowrap; /* 强制文本在一行显示 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 溢出时显示省略号 */
}

.menu-item span {
  position: relative;
  top: -4px;
}

.menu-item img {
  margin-right: 15px;
  width: 20px;
  height: 20px;
}

.menu-item:hover {
  background-color: #f0f0f0;
  color: #000;
}

.menu-item.active {
  background-color: #0f1845 !important; /* Ensure the active menu item has the correct background color */
  color: #fff; /* White text color for active item */
}

.menu-item:hover,
.menu-item.active {
  cursor: pointer;
}
</style>
