<template>
  <router-view />
</template>

<script setup>
import { onMounted } from "vue";
import router from "./router";

onMounted(() => {
  const token = localStorage.getItem("jwt_token");
  if (!token) {
    router.push({ name: "Login" });
  } else {
    // 没首页 跳转 product
    if (
      router.currentRoute.value.fullPath == "/" ||
      router.currentRoute.value.fullPath == ""
    ) {
      router.push({ name: "Product" });
    }
  }
});
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

/* 解决 自动填充 字体变白 */
:deep input:-webkit-autofill,
:deep textarea:-webkit-autofill,
:deep select:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}
</style>